<template>
  <div style="width: 100%; height: 100%; position: relative">
    <div class="top">
      <div class="item-search">
        <span
          style="color: #515a6e; font-size: 14px; margin-left: 10px"
          class="key blod"
          >设备：</span
        >
        <el-select
          v-model="formData.unit_member"
          class="value"
          placeholder="请选择"
        >
          <el-option
            v-for="sub in options"
            :key="sub.unit_member"
            :label="sub.unit_name"
            :value="sub.unit_member"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-search">
        <span
          style="color: #515a6e; font-size: 14px; margin-left: 10px"
          class="key blod"
          >时间：</span
        >
        <el-date-picker
          v-model="time"
          class="date-value"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </div>
      <el-button :loading='isLoad' @click="submit" style="margin-left: 20px" type="primary"
        >确定</el-button
      >
      <el-button @click="init" style="margin-left: 20px" type="warning"
        >初始化</el-button
      >
    </div>
    <baidu-map
      v-if="isShow"
      style="width: 100%; height: 100%"
      :ak="mapStyle.AK"
      :scroll-wheel-zoom="mapStyle.SCROLLWHEELZOOM"
      :center="center"
      :zoom="zoom"
      :mapStyle="theme"
      @ready="handler"
    >
      <map-marker
        v-for="(item, index) in markers"
        :key="index"
        :position="item | changePos"
        :icon="mapStyle.MARKERICON(item)"
        @click="getMarker(item)"
      >
      <map-label
          :content="item.unit_name"
          :labelStyle="mapStyle.LABELSTYLE"
          :offset="mapStyle.LABELOFFSET"
        ></map-label>
      </map-marker>

      <map-polyline
        :path="poylines"
        stroke-color="blue"
        :stroke-opacity="0.5"
        :stroke-weight="2"
      ></map-polyline>
      <!-- pyMarker -->
      <map-marker
        v-for="(item) in pyMarker"
        :key="item.lng"
        :position="item.loca"
        :icon="item.icon"
        @click="getMarker(item)"
      >
      <map-label
          :content="item.name"
          :labelStyle="mapStyle.LABELSTYLE"
          :offset="mapStyle.LABELOFFSET"
        ></map-label>
      </map-marker>

      <map-info-window :position='info.item | changePos' @close='isShowInfo = false' @open='isShowInfo = true' :show='isShowInfo' title="设备信息">
        <div style="margin-top: 20px; font-size:14px; color:#A4A8AC;"><span style="color: #515A6E; font-size:14px">单兵名称：</span>{{info.name}}</div>
        <div style="font-size:14px; color:#A4A8AC;"><span style="color: #515A6E; font-size:14px">设备编号：</span>{{info.num}}</div>
        <div style="font-size:14px; color:#A4A8AC;"><span style="color: #515A6E; font-size:14px">单兵位置：</span>{{info.address}}</div>
      </map-info-window>
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map' // 地图组件
import MapPolyline from 'vue-baidu-map/components/overlays/Polyline'
import MapMarker from 'vue-baidu-map/components/overlays/Marker' // 地图点
import MapLabel from 'vue-baidu-map/components/overlays/Label' // 地图点 标签
import MapInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow' // 信息窗口
import { MAP_STYLE } from '@/utils/configMap'
import { STYLE } from '@/js/theme.js'
import { getDeviceMarker, getDeviceLine } from '@/api/monitor'
import { getConfig, getDevSelect } from '@/api/common'
// import { dateMap } from '@/utils/util'
export default {
  name: 'DeviceLocation',
  components: {
    BaiduMap,
    MapMarker,
    MapPolyline,
    MapLabel,
    MapInfoWindow,
  },
  props: {
    localChange: Function,
  },
  data() {
    return {
      mapStyle: MAP_STYLE,
      theme: {
        styleJson: STYLE,
      },
      isShow: false,
      center: {}, // 中心点
      zoom: 15, // 级别
      address: '', // 地址
      current: {}, // 当前Ponit点
      BMap: {}, // 地图实例
      map: {}, // 地图组件
      markers: [],
      initMap: {}, // 初始化地图
      formData: {
        unit_member: '',
        time_at: '',
        time_end: '',
      },
      time: '',
      options: [],
      poylines: [],
      pyMarker: [],
      info: {
        item: {},
        name: '',
        address: '',
        num: '',
      },
      isShowInfo: false,
      isLoad: false,
    }
  },
  created() {
    this._loadData()
    this._loadConfig()
    this._loadSelect()
    // getConfig
  },
  updated() {
    this.$store.commit('SET_BC', false)
    this.$store.commit('SET_COLOR', '#000')
  },
  beforeDestroy() {
    this.$store.commit('SET_BC', true)
    this.$store.commit('SET_COLOR', '')
  },
  filters: {
    changePos: function ({ mlat, mlng }) {
      if (!mlat && !mlng) return { lat: 0, lng: 0 }
      else return { lat: Number(mlat), lng: Number(mlng) }
    },
  },
  methods: {
    _loadSelect: function () {
      getDevSelect().then((res) => {
        const { result } = res
        // result.unshift({ unit_member: '', unit_name: '默认' })
        this.options = result
      })
    },
    _loadConfig: function () {
      getConfig('map_config').then((res) => {
        const { result } = res
        result.forEach((item) => {
          if (item.type === 'level') {
            this.initMap.zoom = Number(item.name)
          }
          if (item.type === 'lat') {
            this.initMap.lat = item.name
          }
          if (item.type === 'lng') {
            this.initMap.lng = item.name
          }
        })
        this.isShow = true
      })
    },
    _loadData: function () {
      getDeviceMarker().then((res) => {
        const { result } = res
        const { status } = result
        this.markers = status
        // this.isShow = true
      })
    },

    init: function() {
      for (const k in this.formData) {
        this.formData[k] = ''
      }
      this.time = ''
      this.poylines = []
      this.pyMarker = []
      this._loadData()
      const { lng = 120.514466, lat = 30.319445, zoom = 15 } = this.initMap
      this.center = {
        lng,
        lat,
      }
      this.zoom = zoom
    },

    // 初始化地图
    handler: function ({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      const { lng = 120.514466, lat = 30.319445, zoom = 15 } = this.initMap
      this.center = {
        lng,
        lat,
      }
      this.zoom = zoom
    },

    getMarker: function(item) {
      this.info.item = item
      this.info.num = item.id
      this.info.address = item.ps
      this.info.name = item.unit_name
      this.isShowInfo = true
    },

    submit: function () {
      this.isLoad = true
      this.formData.time_at = ''
      this.formData.time_end = ''
      if (this.time) {
        const sevenDay = 24 * 60 * 60 * 1000 * 7

        const start = this.time[0].getTime()
        const end = this.time[1].getTime()
        const diff = end - start

        if (diff < sevenDay) {
          // const t = dateMap(this.time)
          this.formData.time_at = start
          this.formData.time_end = end
        } else {
          this.$toast('只能选择七天以内的轨迹')
          return false
        }
      } else {
        this.$toast('请选择时间')
        return false
      }

      if (!this.formData.unit_member) {
        this.$toast('请选择单兵设备')
        return false
      }
      getDeviceLine(this.formData).then((res) => {
        const { result } = res
        const { tracks } = result
        this.poylines = []
        this.pyMarker = []
        this.isLoad = false
        !tracks && (this.$toast('暂无轨迹'))
        // eslint-disable-next-line no-unused-expressions
        tracks.length &&
          (this.isShow = false,
          tracks.forEach(s => {
            this.poylines.push({
              lng: Number(s.mlng),
              lat: Number(s.mlat),
            })
          }),
          this.pyMarker = [
            {
              loca: this.poylines[0],
              icon: {
                url: require('@/assets/map/start.png'),
                size: { width: 38, height: 70 },
              },
              name: '开始位置',
            },
            {
              loca: this.poylines[this.poylines.length - 1],
              icon: {
                url: require('@/assets/map/end.png'),
                size: { width: 38, height: 70 },
              },
              name: '结束位置',
            },
          ],
          this.markers = [],
          this.center = this.poylines[0],
          this.isShow = true
          )
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #000;
  z-index: 9999;
  display: flex;
  align-items: center;
}
</style>
